import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
//import { Link } from "react-router-dom";

import { MetaTags } from "react-meta-tags";
import { Form, FormGroup } from "reactstrap";
//import Vector from "../Maps/Vectormap";
import "../../assets/scss/custom.scss";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";
//import ReactApexChart from 'react-apexcharts';
import "chartist/dist/scss/chartist.scss";
import { Bar } from "react-chartjs-2";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";
//import Clock from 'react-live-clock';
import moment from "moment";
import DatePicker from "react-datepicker";
import { ThemeContext } from "../../ThemeContext";

// const progressBar = {
//   height: '5px'
// }
// const countryName = {
//   float:'left'
// }
// const countryCount = {
//   float:'right'
// }

// const countryLine = {
//   display: 'flow-root',
//   marginBottom: '0px'
// }

class Homepage extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      version: 0,
      diagnosis: 0,
      users: 0,
      ver_count: [],
      version_list: [],
      version_items: [],
      diag_list: [],
      diag_items: [],
      loading: true,
      diagnosis_visiblity: false,
      version_visiblity: false,
      diagnosis_test_visiblity: false,
      version_test_visiblity: false,
      users_visiblity: false,
      statistic_visibility: false,
      tickets_visibility: false,
      custom_date_selector_visibility: false,
      diagnosis_month_year_selector_visibility: false,
      custom_month_year: "",
      start_date: new Date(),
      ver_bar_selected: localStorage.getItem("ver_bar"),
      diag_bar_selected: localStorage.getItem("diag_bar"),
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.handleVerBarChange = this.handleVerBarChange.bind(this);
    this.handleDiagBarChange = this.handleDiagBarChange.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleYear = this.handleYear.bind(this);
    // this.getFilteredDateData = this.getFilteredDateData.bind(this);
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var diag_bar_visibility_check = localStorage.getItem("diag_bar");
    if (diag_bar_visibility_check === "month") {
      this.setState({ diagnosis_month_year_selector_visibility: true });
    } else {
      this.setState({ diagnosis_month_year_selector_visibility: false });
    }

    var diagnosis_custom_month_year = localStorage.getItem(
      "diagnosis_custom_month_year"
    );
    this.setState({ custom_month_year: diagnosis_custom_month_year });

    //console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses);

        if (findresponses.homestats === 1) {
          this.setState({
            statistic_visibility: true,
          });
        }
        if (findresponses.users === 1) {
          this.setState({
            users_visiblity: true,
          });
        }
        if (findresponses.diagnosis === 1) {
          this.setState({
            diagnosis_visiblity: true,
          });
        }
        if (findresponses.versions === 1) {
          this.setState({
            version_visiblity: true,
          });
        }
        if (findresponses.diag_test === 1) {
          this.setState({
            diagnosis_test_visiblity: true,
          });
        }
        if (findresponses.ver_test === 1) {
          this.setState({
            version_test_visiblity: true,
          });
        }
        if (findresponses.tickets === 1) {
          this.setState({
            tickets_visibility: true,
          });
        }
      });

    var ver_bar = localStorage.getItem("ver_bar");
    if (ver_bar) {
      var ver_bar_n = ver_bar;
    } else {
      var ver_bar_n = "software";
    }
    //console.log(ver_bar);

    const days = localStorage.getItem("data_visible_days");
    const selected_product = localStorage.getItem("sel_product");
    const user_language = localStorage.getItem("user_db_lang");
    const data_source = localStorage.getItem("data_source");

    if (!selected_product) {
      var sel_product = "all";
    } else {
      var sel_product = selected_product;
    }

    if (data_source === "internal") {
      var data_source_final = "test_get_versions_count";
    } else {
      var data_source_final = "get_versions_count";
    }

    //Version Count
    fetch(
      config.API +
        // "get_versions_count"+
        data_source_final +
        "?" +
        "company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          version: findresponse.version_count,
        });
      });

    if (data_source === "internal") {
      var data_source_final = "test_get_diagnosis_count";
    } else {
      var data_source_final = "get_diagnosis_count";
    }
    //Diagnosis Count
    fetch(
      config.API +
        // "get_diagnosis_count"+
        data_source_final +
        "?" +
        "company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          diagnosis: findresponse.diag_count,
        });
      });

    //Users Count
    // fetch(
    //   config.API +
    //   "get_users_count?company=Nexion",
    //   {
    //     headers: {
    //       Nexion_API_Key: config.API_KEY,
    //     },
    //   }
    // )
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     this.setState({
    //       users: findresponse.users_count,
    //     });
    //   });

    //Diagnosis Bar
    if (data_source === "internal") {
      var data_source_final = "test_get_diag_stats/";
    } else {
      var data_source_final = "get_diag_stats/";
    }

    var diag_bar = localStorage.getItem("diag_bar");
    const starting_date = moment(this.state.start_date).format("YYYY-MM-DD");
    if (diag_bar === "custom") {
      this.setState({
        custom_date_selector_visibility: true,
        diag_bar_selected: "custom",
        loading: false,
      });
      fetch(
        config.API +
          // "get_diag_stats/" +
          data_source_final +
          diag_bar +
          "?company=Nexion&custom_date=" +
          starting_date +
          "&user_lang=" +
          user_language +
          "&sel_product=" +
        sel_product,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            diag_list: findresponse.diagnosis_items.d_list,
            diag_items: findresponse.diagnosis_items.d_items,
            loading: false,
          });
        });
    } else {
      fetch(
        config.API +
          // "get_diag_stats/" +
          data_source_final +
          diag_bar +
          "?company=Nexion&custom_date=" +
          starting_date +
          "&custom_year=" +
          diagnosis_custom_month_year +
          "&user_lang=" +
          user_language +
          "&sel_product=" +
        sel_product,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            diag_list: findresponse.diagnosis_items.d_list,
            diag_items: findresponse.diagnosis_items.d_items,
            loading: false,
          });
        });
    }
    if (data_source === "internal") {
      var data_source_final = "test_get_ver_stats/";
    } else {
      var data_source_final = "get_ver_stats/";
    }

    //Version Bar
    fetch(
      config.API +
        // "get_ver_stats/" +
        data_source_final +
        ver_bar_n +
        "?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log("GET", findresponse);
        this.setState({
          ver_count: findresponse.version_count,
          version_list: findresponse.version_list.v_list,
          version_items: findresponse.version_list.v_items,
          loading: false,
        });
      });
  }

  handleVerBarChange(prod) {
    localStorage.setItem("ver_bar", prod.target.value);
    //alert(lang.target.value);
    var ver_bar = prod.target.value;
    const days = localStorage.getItem("data_visible_days");
    const selected_product = localStorage.getItem("sel_product");
    const data_source = localStorage.getItem("data_source");
    const user_language = localStorage.getItem("user_db_lang");
    if (!selected_product) {
      var sel_product = "all";
    } else {
      var sel_product = selected_product;
    }

    this.setState({
      ver_bar_selected: prod.target.value,
    });
    //console.log(ver_bar);
    if (data_source === "internal") {
      var data_source_final = "test_get_ver_stats/";
    } else {
      var data_source_final = "get_ver_stats/";
    }
    fetch(
      config.API +
        // "get_ver_stats/" +
        data_source_final +
        ver_bar +
        "?company=Nexion&days=" +
        days +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          ver_count: findresponse.ver_count,
          version_list: findresponse.version_list.v_list,
          version_items: findresponse.version_list.v_items,
          loading: false,
        });
      });

    //window.location.reload(false);
  }

  handleStart(date) {
    this.setState({ start_date: date });
    const starting_date = moment(date).format("YYYY-MM-DD");
    const user_language = localStorage.getItem("user_db_lang");
    const selected_product = localStorage.getItem("sel_product");
    const data_source = localStorage.getItem("data_source");
    if (data_source === "internal") {
      var data_source_final = "test_get_diag_stats/";
    } else {
      var data_source_final = "get_diag_stats/";
    }

    if (!selected_product) {
      var sel_product = "all";
    } else {
      var sel_product = selected_product;
    }
    fetch(
      config.API +
        // "get_diag_stats/"+
        data_source_final +
        "custom?company=Nexion&custom_date=" +
        starting_date +
        "&user_lang=" +
        user_language +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          diag_list: findresponse.diagnosis_items.d_list,
          diag_items: findresponse.diagnosis_items.d_items,
          loading: false,
        });
      });
  }

  handleYear(year) {
    var selectedYear = year.target.value;
    var diag_bar = localStorage.getItem("diag_bar");
    localStorage.setItem("diagnosis_custom_month_year", selectedYear);
    var currDate = this.state.start_date;
    const starting_date = moment(currDate).format("YYYY-MM-DD");
    const user_language = localStorage.getItem("user_db_lang");
    const data_source = localStorage.getItem("data_source");
    if (data_source === "internal") {
      var data_source_final = "test_get_diag_stats/";
    } else {
      var data_source_final = "get_diag_stats/";
    }

    const selected_product = localStorage.getItem("sel_product");

    if (!selected_product) {
      var sel_product = "all";
    } else {
      var sel_product = selected_product;
    }

    fetch(
      config.API +
        // "get_diag_stats/" +
        data_source_final +
        diag_bar +
        "?company=Nexion&custom_date=" +
        starting_date +
        "&custom_year=" +
        selectedYear +
        "&user_lang=" +
        user_language +
        "&sel_product=" +
        sel_product,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          diag_list: findresponse.diagnosis_items.d_list,
          diag_items: findresponse.diagnosis_items.d_items,
          loading: false,
        });
      });
  }

  handleDiagBarChange(prod) {
    const starting_date = moment(this.state.start_date).format("YYYY-MM-DD");
    localStorage.setItem("diag_bar", prod.target.value);
    const user_language = localStorage.getItem("user_db_lang");

    var selectedYear = localStorage.getItem("diagnosis_custom_month_year");
    var diag_bar = prod.target.value;
    const selected_product = localStorage.getItem("sel_product");
    const data_source = localStorage.getItem("data_source");
    if (data_source === "internal") {
      var data_source_final = "test_get_diag_stats/";
    } else {
      var data_source_final = "get_diag_stats/";
    }

    if (!selected_product) {
      var sel_product = "all";
    } else {
      var sel_product = selected_product;
    }

    if (diag_bar === "custom") {
      this.setState({
        custom_date_selector_visibility: true,
        diagnosis_month_year_selector_visibility: false,
        diag_bar_selected: prod.target.value,
      });
      fetch(
        config.API +
          // "get_diag_stats/" +
          data_source_final +
          diag_bar +
          "?company=Nexion&custom_date=" +
          starting_date +
          "&custom_year=" +
          selectedYear +
          "&user_lang=" +
          user_language +
          "&sel_product=" +
          sel_product,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            diag_list: findresponse.diagnosis_items.d_list,
            diag_items: findresponse.diagnosis_items.d_items,
            loading: false,
          });
        });
    } else if (diag_bar === "month") {
      this.setState({
        diagnosis_month_year_selector_visibility: true,
        custom_date_selector_visibility: false,
        diag_bar_selected: prod.target.value,
      });
      fetch(
        config.API +
          // "get_diag_stats/" +
          data_source_final +
          diag_bar +
          "?company=Nexion&custom_date=" +
          starting_date +
          "&custom_year=" +
          selectedYear +
          "&user_lang=" +
          user_language +
          "&sel_product=" +
          sel_product,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            diag_list: findresponse.diagnosis_items.d_list,
            diag_items: findresponse.diagnosis_items.d_items,
            loading: false,
          });
        });
    } else {
      this.setState({
        custom_date_selector_visibility: false,
        diagnosis_month_year_selector_visibility: false,
      });
      this.setState({
        diag_bar_selected: prod.target.value,
      });
      fetch(
        config.API +
          // "get_diag_stats/" +
          data_source_final +
          diag_bar +
          "?company=Nexion&custom_date=" +
          starting_date +
          "&user_lang=" +
          user_language +
          "&sel_product=" +
          sel_product,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            diag_list: findresponse.diagnosis_items.d_list,
            diag_items: findresponse.diagnosis_items.d_items,
            loading: false,
          });
        });
    }
  }

  // getFilteredDateData() {
  //   const starting_date = moment(this.state.start_date).format("YYYY-MM-DD");
  //   fetch(
  //     config.API +
  //       "get_diag_stats/custom?company=Nexion&custom_date=" +
  //       starting_date,
  //     {
  //       headers: {
  //         Nexion_API_Key: config.API_KEY,
  //       },
  //     }
  //   )
  //     .then((Response) => Response.json())
  //     .then((findresponse) => {
  //       this.setState({
  //         diag_list: findresponse.diagnosis_items.d_list,
  //         diag_items: findresponse.diagnosis_items.d_items,
  //         loading: false,
  //       });
  //     });
  // }

  render() {
    const { t } = this.props;

    const data = {
      labels: this.state.version_list,
      datasets: [
        {
          label: t("homepage.instruments"),
          backgroundColor: "#00A2E8",
          borderColor: "#ffffff",
          borderWidth: 1,
          hoverBackgroundColor: "#006464",
          hoverBorderColor: "#003333",
          data: this.state.version_items,
        },
      ],
    };

    const diagdata = {
      labels: this.state.diag_list,
      datasets: [
        {
          label: t("homepage.diagnosis"),
          backgroundColor: "#FF7F27",
          borderColor: "#ffffff",
          borderWidth: 1,
          hoverBackgroundColor: "#006464",
          hoverBorderColor: "#003333",
          data: this.state.diag_items,
        },
      ],
    };

    const option = {
      toolbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            //var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            //var percentage = parseFloat((currentValue / total * 100).toFixed(1));
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const option_diag = {
      toolbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            //var meta = dataset._meta[Object.keys(dataset._meta)[0]];
            //var total = meta.total;
            var currentValue = dataset.data[tooltipItem.index];
            //var percentage = parseFloat((currentValue / total * 100).toFixed(1));
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };
    return (
      <ThemeContext.Consumer>
        {(value) => (
          <React.Fragment>
            <MetaTags>
              <title>Home | Nexion Engineering Web Portal</title>
              <meta
                name="description"
                content="Diagnostic Portal For Nexion Engineering"
              />
            </MetaTags>
            <Sugar
              customLoading={this.state.loading}
              background="#016362"
              color={"#ffffff"}
            />
            <div className="container-fluid">
              <Row className="homepageFirstRow">
                <Col xl={2}>
                  <Card>
                    <Link to="/dashboard" className="home_link">
                      <CardBody className="text-center homeIconCardBody">
                        <i class="ion ion-ios-speedometer text-nexion text-center homeIcon"></i>
                        <p>{t("menus.dashboard")}</p>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
                {/* <Col xl={2}>
              <Card>
                <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-time text-danger text-center homeIcon"></b>
                <p>{t('menus.logs')}</p>
                </CardBody>
              </Card>
            </Col> */}
                {this.state.users_visiblity === true ? (
                  <Col xl={2}>
                    <Card>
                      <Link to="/users" className="home_link">
                        <CardBody className="text-center homeIconCardBody">
                          <b class="ion ion-ios-person text-nexion text-center homeIcon"></b>
                          <p>{t("menus.users")}</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ) : (
                  ""
                )}
                {this.state.diagnosis_visiblity === true ? (
                  <Col xl={2}>
                    <Card>
                      <Link to="/diagnosis" className="home_link">
                        <CardBody className="text-center homeIconCardBody">
                          <b class="ion ion-ios-medkit text-nexion text-center homeIcon"></b>
                          <p>{t("menus.diagnosis")}</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ) : (
                  ""
                )}

                {/* {this.state.diagnosis_test_visiblity === true ? (
                <Col xl={2}>
                  <Card>
                    <Link to="/diagnosis_test" className="home_link">
                      <CardBody className="text-center homeIconCardBody">
                        <b class="ion ion-ios-medkit text-nexion text-center homeIcon"></b>
                        <p>{t("menus.diagnosis_test")}</p>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
              ) : (
                ""
              )} */}

                {this.state.version_visiblity === true ? (
                  <Col xl={2}>
                    <Card>
                      <Link to="/versions" className="home_link">
                        <CardBody className="text-center homeIconCardBody">
                          <b class="ion ion-ios-list text-nexion text-center homeIcon"></b>
                          <p>{t("menus.versions")}</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ) : (
                  ""
                )}

                {this.state.tickets_visibility === true ? (
                  <Col xl={2}>
                    <Card>
                      <Link to="/tickets" className="home_link">
                        <CardBody className="text-center homeIconCardBody">
                          <b class="mdi mdi-jira text-nexion text-center homeIcon_ticket"></b>
                          <p>{t("menus.tickets_page")}</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ) : (
                  ""
                )}
                  <Col xl={2}>
                    <Card>
                      <Link to="/parametri" className="home_link">
                        <CardBody className="text-center homeIconCardBody">
                          <b class="ion ion ion-ios-cog text-nexion text-center homeIcon"></b>
                          <p>Parametri</p>
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                
                {/* {this.state.version_test_visiblity === true ? (
                <Col xl={2}>
                  <Card>
                    <Link to="/versions_test" className="home_link">
                      <CardBody className="text-center homeIconCardBody">
                        <b class="ion ion-ios-list text-nexion text-center homeIcon"></b>
                        <p>{t("menus.version_test")}</p>
                      </CardBody>
                    </Link>
                  </Card>
                </Col>
              ) : (
                ""
              )} */}
                {/* <Col xl={2}>
            <Card>
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-construct text-danger text-center homeIcon"></b>
                <p>{t('menus.parts')}</p>
                </CardBody>
              </Card>
            </Col> */}
                {/* <Col xl={2}>
            <Card>
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-cog text-danger text-center homeIcon"></b>
                <p>{t('menus.parameters')}</p>
                </CardBody>
              </Card>
            </Col> */}
                {/* <Col xl={2}>
            <Card>
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-alert text-danger text-center homeIcon"></b>
                <p>{t('menus.error_all')}</p>
                </CardBody>
              </Card>
            </Col> */}
              </Row>
              <Row>
                {/* <Col xl={2}>
            <Card>
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-bug text-danger text-center homeIcon"></b>
                <p>{t('menus.eobd_errors')}</p>
                </CardBody>
              </Card>
            </Col> */}
                {/* <Col xl={2}>
            <Card>
            <Link to="/diagnosis" className="home_link">
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-medkit text-danger text-center homeIcon"></b>
                <p>{t('menus.diagnosis')}</p>
                </CardBody>
                </Link>
              </Card>
            </Col>
            <Col xl={2}>
            <Card>
            <Link to="/versions" className="home_link">
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-list text-danger text-center homeIcon"></b>
                <p>{t('menus.versions')}</p>
                </CardBody>
                </Link>
              </Card>
            </Col> */}
                {/* <Col xl={2}>
            <Card>
            <CardBody className="text-center homeIconCardBody">
                <b class="ion ion-ios-key text-danger text-center homeIcon"></b>
                <p>{t('menus.secure_gateway')}</p>
                </CardBody>
              </Card>
            </Col> */}
              </Row>
              {/* <Col xl={5}>
              <Card>
                  <CardBody className="height-600">
                  <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.countries')}</h4> */}
              {/* <p>{t('homepage.last_14_days')}</p> */}
              {/* <Row> */}
              {/* <Col xl={4}>
                    <p className="card-title-desc">
                      <b>9,114 - {t('homepage.all_tests')}</b>
                    </p>
  
                    <div className="">
                      <div className="mb-10">
                        <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>1896</span></p>
                        <Progress style={progressBar} color="danger" value={100}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>647</span></p>
                      <Progress style={progressBar} color="danger" value={40}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>United Kindom</span><span style={countryCount}>575</span></p>
                      <Progress style={progressBar} color="danger" value={35}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>558</span></p>
                      <Progress style={progressBar} color="danger" value={33}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>350</span></p>
                      <Progress style={progressBar} color="danger" value={25}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>321</span></p>
                      <Progress style={progressBar} color="danger" value={23}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>289</span></p>
                      <Progress style={progressBar} color="danger" value={20}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>Australia</span><span style={countryCount}>287</span></p>
                      <Progress style={progressBar} color="danger" value={19}></Progress>
                      </div>
                      <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>273</span></p>
                      <Progress style={progressBar} color="danger" value={15}></Progress>
                      </div>
                      
                    </div>
                    </Col> */}
              {/* <Col xl={12}>
                    <div id="world-map-markers" class="vector-map-height">
                      <Vector
                        value="world_mill"
                        width="100%"
                        color="rgb(255, 210, 0)"
                      />
                    </div>
                    </Col>
                  </Row>
                  
                  </CardBody>
              </Card>
              </Col> */}
              <Row className="horizontalCard">
                <Col xl={6}>
                  <Card>
                    <CardBody className="height-200">
                      <h4 className="card-title">
                        <i className="ion ion-md-analytics text-nexion"></i>{" "}
                        {t("homepage.version")}
                      </h4>
                      {/* <p>{t('homepage.last_14_days')}</p> */}
                      <div className="text-center ">
                        <h1 className="text-center text-nexion">
                          {/* {this.state.version.length === 0
                            ? 0
                            : this.state.version} */}
                          {this.state.version}
                        </h1>
                        <p className="font-size-14 pt-1">
                          {t("homepage.instruments")}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card>
                    <CardBody className="height-200">
                      <h4 className="card-title">
                        <i className="ion ion-md-analytics text-nexion"></i>{" "}
                        {t("homepage.diagnosis")}
                      </h4>
                      {/* <p>{t('homepage.last_14_days')}</p> */}
                      <div className="text-center ">
                        <h1 className="text-center text-nexion">
                          {/* {this.state.diagnosis.length === 0
                            ? 0
                            : this.state.diagnosis} */}
                          {this.state.diagnosis}
                        </h1>
                        <p className="font-size-14 pt-1">
                          {t("homepage.diagnosis")}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col xl={3}>
                    <Card>
                      <CardBody className="height-200">
                      <h4 className="card-title"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.users')}</h4>
                      
                      <div className="text-center py-4">
                          <h1 className="text-center text-danger">{this.state.users}</h1>
                          
                      </div>
                      </CardBody>
                    </Card>
                  </Col> */}
              </Row>
              <Row>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        {t("homepage.version")}
                      </h4>
                      <Row>
                        <Col xl={5}>
                          <select
                            className="form-control"
                            value={this.state.ver_bar_selected}
                            onChange={this.handleVerBarChange}
                          >
                            {/* <option value="software">Software</option> */}
                            <option value="vehicle_database">
                              Vehicle Selection DB
                            </option>
                            <option value="ok_function_database">
                              OK Fnc. DB
                            </option>
                            <option value="plants_database">Plants DB</option>
                            <option value="language_database">Lang. DB</option>
                            <option value="vehicle_properties">
                              Vehicle DB
                            </option>
                            <option value="autodiagnosis">Autodiagnosis</option>
                            <option value="sw_update">Ver Update</option>
                            <option value="nxevci">Framework</option>
                            <option value="library">Library</option>
                            <option value="scripts">Scripts</option>
                            <option value="kernel">Kernel</option>
                            <option value="firmware">Firmware</option>
                          </select>
                        </Col>
                        <Col xl={7}></Col>
                      </Row>

                      <React.Fragment>
                        <Bar
                          width={500}
                          height={250}
                          redraw={true}
                          data={{
                            ...data,
                            ...{
                              datasets: [
                                {
                                  label: t("homepage.instruments"),
                                  backgroundColor:
                                    value.theme === "Dark"
                                      ? "#f0ad4e"
                                      : "#00A2E8",
                                  borderColor: "inherit",
                                  borderWidth: 1,
                                  hoverBackgroundColor:
                                    value.theme === "Dark"
                                      ? "#ffffff"
                                      : "#inherit",
                                  hoverBorderColor: "inherit",
                                  data: this.state.version_items,
                                },
                              ],
                            },
                          }}
                          options={{
                            ...option,
                            legend: {
                              labels: {
                                fontColor:
                                  value.theme === "Dark"
                                    ? "#bdbdbd"
                                    : "#5b626b",
                              },
                            },
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                    fontColor:
                                      value.theme === "Dark"
                                        ? "#bdbdbd"
                                        : "#5b626b",
                                  },
                                },
                              ],
                              xAxes: [
                                {
                                  ticks: {
                                    fontColor:
                                      value.theme === "Dark"
                                        ? "#bdbdbd"
                                        : "#5b626b",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </React.Fragment>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">
                        {t("homepage.diagnosis")}
                      </h4>
                      <Row>
                        <Col xl={3}>
                          <select
                            className="form-control"
                            value={this.state.diag_bar_selected}
                            onChange={this.handleDiagBarChange}
                          >
                            <option value="year">{t("homepage.years")}</option>
                            <option value="month">
                              {t("homepage.months")}
                            </option>
                            <option value="days">
                              {t("homepage.last14days")}
                            </option>
                            <option value="custom">
                              {t("homepage.custom")}
                            </option>
                          </select>
                        </Col>
                        {this.state.custom_date_selector_visibility === true ? (
                          <Col xl={8}>
                            <Form action="#">
                              <FormGroup className="mb-0 forminput">
                                <Row>
                                  <Col md={12}>
                                    <label>
                                      {t("diagnosispage.start_date")}
                                    </label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <DatePicker
                                      selected={this.state.start_date}
                                      selectsStart
                                      id="date_pick"
                                      className="date_pick form-control"
                                      placeholderText="From"
                                      name="start_dates"
                                      value={this.state.start_date}
                                      onChange={this.handleStart}
                                    />
                                    {/* <button
                                    type="button"
                                    onClick={this.getFilteredDateData}
                                    className="btn custom_date_filter_btn waves-effect waves-light"
                                  >
                                    {t("diagnosispage.proceed")}
                                  </button> */}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Form>
                          </Col>
                        ) : (
                          ""
                        )}

                        {this.state.diagnosis_month_year_selector_visibility ===
                        true ? (
                          <Col xl={9}>
                            <Form action="#">
                              <FormGroup className="mb-0 forminput">
                                <Row>
                                  <Col md={4}>
                                    {/* <Row> */}
                                    {/* <Col sm={3}> */}
                                    {/* <label>YEAR</label> */}
                                    {/* </Col> */}

                                    {/* <Col sm={9}> */}

                                    <select
                                      className="form-control"
                                      value={localStorage.getItem(
                                        "diagnosis_custom_month_year"
                                      )}
                                      onChange={this.handleYear}
                                    >
                                      <option value="2020">2020</option>
                                      <option value="2021">2021</option>
                                      <option value="2022">2022</option>
                                      <option value="2023">2023</option>
                                      <option value="2024">2024</option>
                                      <option value="2025">2025</option>
                                    </select>
                                    {/* </Col> */}
                                    {/* </Row> */}
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Form>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                      <React.Fragment>
                        <Bar
                          width={500}
                          height={250}
                          redraw={true}
                          data={{
                            ...diagdata,
                            ...{
                              datasets: [
                                {
                                  label: t("homepage.diagnosis"),
                                  backgroundColor:
                                    value.theme === "Dark"
                                      ? "#99D8D7"
                                      : "#FF7F27",
                                  borderColor: "inherit",
                                  borderWidth: 1,
                                  hoverBackgroundColor:
                                    value.theme === "Dark"
                                      ? "#bdbdbd"
                                      : "inherit",
                                  hoverBorderColor: "inherit",
                                  data: this.state.diag_items,
                                },
                              ],
                            },
                          }}
                          options={{
                            ...option_diag,
                            legend: {
                              labels: {
                                fontColor:
                                  value.theme === "Dark"
                                    ? "#bdbdbd"
                                    : "#5b626b",
                              },
                            },
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                    fontColor:
                                      value.theme === "Dark"
                                        ? "#bdbdbd"
                                        : "#5b626b",
                                  },
                                },
                              ],
                              xAxes: [
                                {
                                  ticks: {
                                    fontColor:
                                      value.theme === "Dark"
                                        ? "#bdbdbd"
                                        : "#5b626b",
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </React.Fragment>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Row>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system_category')}</h4>
                    <div id="chart">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                  </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system')}</h4>
                    <div id="chart">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                  </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.eobd')}</h4>
                    <div id="chart">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                  </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.ok_function')}</h4>
                    <div id="chart">
                  <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                  </div>
                  </CardBody>
                </Card>
              </Col>
              </Row> */}
            </div>
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default withTranslation()(Homepage);
