import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import queryString from "query-string";
import logoSm from "../../assets/images/nexion_sm.png";
import Car from "../../assets/images/car_new.jpg";
import i18next from "i18next";
import Table from 'react-bootstrap/Table';
//import moment from 'moment';
import { MetaTags } from "react-meta-tags";
import { Sugar } from "react-preloaders";
import { withTranslation } from "react-i18next";
import config from "./../../helpers/Constants";
import carTop from "../../assets/images/car_top_2.png";

class DiagnosisReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosis: [],
      version: [],
      location: [],
      vehicle: [],
      ecus: [],
      activations: [],
      configurations: [],
      clear_dtc: [],
      dtc: [],
      diag_info: [],
      adas_settings:[],
      adas_precheck:[],
      security_gateway_unlock: [],
      single_adas_operation: [],
      d_date: "",
      continent_code: "",
      continent_name: "",
      country_code: "",
      country_name: "",
      ip_address: "",
      state_prov: "",
      city: "",
      loading: true,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
  }

  componentDidMount() { 
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });
    const values = queryString.parse(this.props.location.search);
    //const version_id = values.id;
    const diag_id = values.diagnosis_id;
    const test_type = values.test_type;
    if (diag_id === undefined) {
      window.history.back();
    }
    fetch(config.API + "diagnosis_report/" + diag_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
        diagnosis_test_type: test_type
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        //console.log(findresponse.diagnosis);
        this.setState({
          diagnosis: findresponse.diagnosis,
          activations: findresponse.diag_activations,
          configurations: findresponse.diag_conf,
          clear_dtc: findresponse.diag_clear_dtc,
          ecus: findresponse.ecus,
          dtc: findresponse.ecus_dtc,
          version: findresponse.versions,
          location: findresponse.location,
          vehicle: findresponse.selected_vehicle,
          diag_info: findresponse.diag_info,
          adas_settings: findresponse.adas_settings,
          adas_precheck: findresponse.adas_precheck,
          d_date: findresponse.diagnosis.diagnosis_date
            .replace(/T/, " ")
            .replace(/\..+/, ""),
          loading: false,
        });
        if (findresponse.location !== undefined) {
          this.setState({
            continent_code: findresponse.location.continent_code,
            continent_name: findresponse.location.continent_name,
            country_code: findresponse.location.country_code,
            country_name: findresponse.location.country_name,
            city: findresponse.location.city,
            state_prov: findresponse.location.state_prov,
            ip_address: findresponse.location.ip_address,
          });
        }
      });

      fetch(config.API + "diagnosis_sao_list/" + diag_id, {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      })
        .then((Response) => Response.json())
        .then((findresponse) => {
          this.setState({
            single_adas_operation: findresponse.single_adas_operation,
          });
        });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MetaTags>
          <title>Diagnosis Report | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box"></div>
            </Col>
          </Row>

          <Row>
            {/* <div className="col-1"></div> */}
            <div className="col-12 report_class">
              <Card>
                <CardBody className="report_card">
                  <Row>
                    <div className="col-12">
                      <Row>
                        <div className="col-8">
                          <h3 className="font-size-28">REPORT</h3>
                        </div>
                        <div className="col-4">
                          <div className="invoice-title">
                            <img
                              src={logoSm}
                              className="float-right"
                              alt="logo"
                              height="50"
                            />
                          </div>
                        </div>
                      </Row>
                      <hr />
                      <Row className="report_top">
                        <div className="col-sm-6">
                          <div className="diagnosis_report_head">
                            <strong>
                              <h5>WORKSHOP DATA</h5>
                            </strong>
                          </div>

                          <div className="table-responsive diagnosis_report_bold_text">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>
                                    {t("diagnosisdetailspage.cont_name")}:
                                  </td>
                                  <td>
                                    {this.state.continent_name !== undefined &&
                                    this.state.continent_name !== ""
                                      ? this.state.continent_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.con_name")}:</td>
                                  <td>
                                    {this.state.country_name !== undefined &&
                                    this.state.country_name !== ""
                                      ? this.state.country_name
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.state")}:</td>
                                  <td>
                                    {this.state.state_prov !== undefined &&
                                    this.state.state_prov !== ""
                                      ? this.state.state_prov
                                      : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.city")}:</td>
                                  <td>
                                    {this.state.city !== undefined &&
                                    this.state.city !== ""
                                      ? this.state.city
                                      : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-sm-6 report_top">
                          <div className="diagnosis_report_head">
                            <strong>
                              <h5>VERSION</h5>
                            </strong>
                          </div>
                          <div className="table-responsive diagnosis_report_bold_text">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>Serial Number:</td>
                                  <td>{this.state.diagnosis.serial_number}</td>
                                </tr>
                                <tr>
                                  <td>SW:</td>
                                  <td>{this.state.version.software}</td>
                                </tr>
                                <tr>
                                  <td>Vehicle DB:</td>
                                  <td>{this.state.version.vehicle_database}</td>
                                </tr>
                                <tr>
                                  <td>Diagnosis:</td>
                                  <td>{this.state.version.autodiagnosis}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Row>
                      <br />
                      <br />
                      <hr />
                      <Row>
                        <div className="col-12">
                          <div className="diagnosis_report_head">
                            <strong>
                              <h5>VEHICLE DATA</h5>
                            </strong>
                          </div>
                          <div className="table-responsive diagnosis_report_bold_text">
                            <table className="table table-sm m-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td>{t("diagnosisdetailspage.brand")}:</td>
                                  <td>{this.state.vehicle.brand}</td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.family")}:</td>
                                  <td>{this.state.vehicle.family}</td>
                                </tr>
                                <tr>
                                  <td>{t("diagnosisdetailspage.eng_code")}:</td>
                                  <td>
                                    {this.state.vehicle.variant_name} [{" "}
                                    {this.state.vehicle.variant_year_from} -{" "}
                                    {this.state.vehicle.variant_year_to} ]{" "}
                                    {this.state.vehicle.fuel === "D"
                                      ? "Diesel"
                                      : "Petrol"}{" "}
                                    {this.state.vehicle.engine_code_clear} -{" "}
                                    {this.state.vehicle.kw} KW{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td>VIN:</td>
                                  <td>{this.state.vehicle.vin}</td>
                                </tr>
                                <tr>
                                  <td>
                                    {t("diagnosisdetailspage.plate_number")}:
                                  </td>
                                  <td>{this.state.vehicle.plate_number}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className="col-2">
                          <img
                            src={Car}
                            className="float-right"
                            alt="car"
                            width="100%"
                          />
                        </div> */}
                      </Row>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12">
                      <div>
                        <br />
                        <div className="diagnosis_report_head">
                          <strong>
                            <h5>VEHICLE STATUS</h5>
                          </strong>
                          {/* <p><b>Battery:</b>{this.state.battery_info.battery} V</p> */}

                          <span>
                            <b>ECU Scan Date:</b> {this.state.d_date}
                          </span>
                        </div>
                        <div className="">
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>Group</strong>
                                  </td>
                                  {/* <td className="text-center">
                                    <strong>Code</strong>
                                  </td> */}
                                  <td className="text-center">
                                    <strong>ECU</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>Status</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.ecus.map((ecu, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {ecu.e_group}
                                      </td>
                                      {/* <td className="text-center">{ecu.code}</td> */}
                                      <td className="text-center">
                                        {ecu.name}
                                      </td>
                                      <td className="text-right">
                                        {ecu.status.toUpperCase()}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>DETECTED ERRORS</h5>
                            </strong>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>ECU</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>DTC</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Hex</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Description</strong>
                                  </td>
                                  <td className="text-right">
                                    <strong>ATT/MEM</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.dtc.map((dt, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">
                                        {dt.e_group} - {dt.name}
                                      </td>
                                      <td className="text-center">
                                        {dt.j2012}
                                      </td>
                                      <td className="text-center">{dt.hex}</td>
                                      <td className="text-center">
                                        {dt.description}
                                      </td>
                                      <td className="text-right">
                                        {dt.status}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>ICM -INSTRUMENT CLUSTER - IPC</h5>
                            </strong>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>Info</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Value</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.diag_info.map((de, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{de.text}</td>
                                      <td className="text-center">
                                        {de.value}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>

                          <br />
                          <div className="p-2">
                            <strong>
                              <h5>OPERATIONS CARRIED OUT</h5>
                            </strong>
                            <p>
                              <b>Activations</b>
                            </p>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>Operation</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Date</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>V Battery</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.activations.map((ac, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{ac.name}</td>
                                      <td className="text-center">
                                        {ac.success === 1
                                          ? "SUCCESS"
                                          : "FAILED"}
                                      </td>
                                      <td className="text-center">
                                        {ac.datetime
                                          .replace(/T/, " ")
                                          .replace(/\..+/, "")}
                                      </td>
                                      <td className="text-center">
                                        {ac.battery}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <p>
                            <b>Configurations</b>
                          </p>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>Operation</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Date</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>V Battery</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.configurations.map((cn, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{cn.name}</td>
                                      <td className="text-center">
                                        {cn.success === 1
                                          ? "SUCCESS"
                                          : "FAILED"}
                                      </td>
                                      <td className="text-center">
                                        {cn.datetime
                                          .replace(/T/, " ")
                                          .replace(/\..+/, "")}
                                      </td>
                                      <td className="text-center">
                                        {cn.battery}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <p>
                            <b>CLEAR_DTC</b>
                          </p>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <thead className="adas_thead">
                                <tr>
                                  <td className="text-center">
                                    <strong>Operation</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Result</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>Date</strong>
                                  </td>
                                  <td className="text-center">
                                    <strong>V Battery</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="tbody_bor_top">
                                {this.state.clear_dtc.map((cn, index) => {
                                  return (
                                    <tr>
                                      <td className="text-center">{cn.name}</td>
                                      <td className="text-center">
                                        {cn.success === 1
                                          ? "SUCCESS"
                                          : "FAILED"}
                                      </td>
                                      <td className="text-center">
                                        {cn.datetime
                                          .replace(/T/, " ")
                                          .replace(/\..+/, "")}
                                      </td>
                                      <td className="text-center">
                                        {cn.battery}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {this.state.single_adas_operation.length !== 0 ?  
                  <div>
                    <br /><hr />
                        <p className="report_adas_head">
                          <b>ADAS</b>
                        </p>
                  
                      {this.state.single_adas_operation.map(function (
                        op,
                        index
                      ) {
                        return (
                                <div>
                                  <center><h6 className="mt-0 mb-3 report_adas_title">
                                  ADAS - {op.diagnosis_op_adas_selection[0].adas_system} - {op.name} - {op.diagnosis_op_adas_selection[0].calibration}
                                  </h6></center>
                                  <br/>
                                  <Row className="diag_info_div adas_info_div">
                                    <Col lg={6}>
                                        <p><b>ADAS Driving Assistance System:</b>{" "}</p>
                                        <p><b>Calibration:</b>{" "}</p>
                                        <p><b>Calibration Type:</b>{" "}</p>
                                    </Col>
                                    <Col lg={6}>
                                      <p>{op.diagnosis_op_adas_selection[0].adas_system}</p>
                                      <p>{op.diagnosis_op_adas_selection[0].calibration}</p>
                                      <p>{op.diagnosis_op_adas_selection[0].calibration_type}</p>
                                    </Col>
                                  </Row>
                                  <br/>
                                  {op.diagnosis_op_equipment_placement.length !== 0 ?
                                  <Row>
                                    <Col lg={12}>
                                    <p>
                            <center className="report_adas_title"><b>EQUIPMENT POSITIONING DATA</b></center>
                          </p>
                          <div className="table-responsive">
                            <table className="table table-sm m-0 table-bordered">
                              <tbody>
                                {/* {this.state.clear_dtc.map((cn, index) => {
                                  return ( */}
                                  {op.diagnosis_op_equipment_placement[0].ROC_operation !== "" ?
                                    <tr>
                                      <td>Performed ROC</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].ROC_operation}
                                      </td>
                                      <td className="text-center">
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_distance !== "" ?
                                    <tr>
                                      <td>Distance</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_distance} {op.diagnosis_op_equipment_placement[0].manual_distance =="1" ? <span> * </span> : ""}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_distance} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_distance}
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_height !== "" ?
                                    <tr>
                                      <td>Height</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_height} {op.diagnosis_op_equipment_placement[0].manual_height =="1" ? <span> * </span> : ""}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_height} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_height}
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_horizontal_deviation !== "" ?
                                    <tr>
                                      <td>Horizontal deviation</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_horizontal_deviation}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_horizontal_deviation} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_horizontal_deviation}
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_orthogonality !== "" ?
                                    <tr>
                                      <td>Orthogonality</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_orthogonality}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_orthogonality} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_orthogonality}
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_distance_2 !== "" ?
                                    <tr>
                                      <td>Distance 2</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_distance_2} {op.diagnosis_op_equipment_placement[0].manual_distance_2 =="1" ? <span> * </span> : ""}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_distance_2} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_distance_2}
                                      </td>
                                    </tr> : ""
                                    }
                                    {op.diagnosis_op_equipment_placement[0].measured_height_2 !== "" ?
                                    <tr>
                                      <td>Height 2</td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].measured_height_2} {op.diagnosis_op_equipment_placement[0].manual_height_2 =="1" ? <span> * </span> : ""}
                                      </td>
                                      <td className="text-center">
                                      {op.diagnosis_op_equipment_placement[0].target_height_2} <span> &#177; </span> {op.diagnosis_op_equipment_placement[0].delta_height_2}
                                      </td>
                                    </tr> : ""
                                    }
                                  {/* );
                                })} */}
                              </tbody>
                            </table>
                          </div>
                                    </Col>
                                  </Row> : ""
                               }
                                  <br />
                                  {op.active_diagnosis.map(function (
                                    ad,
                                    ad_index
                                  ) {
                                      return (
                                        <Card>
                                        <Row>
                                    <Col lg={12}>
                                    <div>
                                    <div className="table-responsive">
                                <table className="table table-sm m-0 table-bordered">
                                <thead className="adas_thead">
                                      <tr>
                                        <th>
                                          Operation
                                        </th>
                                        <th>
                                          RESULT
                                        </th>
                                        <th>
                                          Hour
                                        </th>
                                        <th>
                                          V Battery
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="tbody_bor_top">
                                          <tr>
                                            <td>{ad.name}</td>
                                            <td>
                                              {ad.result_calibration}
                                            </td>
                                            <td>
                                              {ad.datetime
                                                .replace(/T/, " ")
                                                .replace(/\..+/, "")}
                                            </td>
                                            <td>{ad.battery}</td>
                                          </tr>
                                    </tbody>
                                  </table>
                                  </div>
                                  </div></Col></Row>
                                  <br/>
                                <Row>
                                <Col lg={12}>
                                <p>
                                <b>DATA ENTERED</b>
                                </p>
                                <div className="table-responsive">
                                <table className="table table-sm m-0 table-bordered">
                                <thead className="adas_thead">
                                  <tr>
                                    <th>Description</th>
                                    <th className="text-center">Value</th>
                                    <th className="text-center">Unit of Measurement</th>
                                  </tr>
                                </thead>
                                <tbody className="tbody_bor_top">
                                {ad.entered_data.map(function (
                                    ed,
                                    ed_index
                                  ) {
                                      return (
                                        <tr>
                                          <td>{ed.parameter_name}</td>
                                          <td className="text-center">
                                          {ed.parameter_value}
                                          </td>
                                          <td className="text-center">
                                          {ed.parameter_udm}
                                          </td>
                                        </tr>
                                      )})}
                                {/* );
                                })} */}
                                </tbody>
                                </table>
                                </div>
                                </Col>
                                </Row>
                                <br />
                                <Row>
                                <Col lg={12}>
                                <p>
                                <b>PARAMETERS AND STATES DATA</b>
                                </p>
                                <div className="table-responsive">
                                <table className="table table-sm m-0 table-bordered">
                                <thead className="adas_thead">
                                <tr>
                                <th>Parameters and States</th>
                                <th className="text-center">Pre Value</th>
                                <th className="text-center">Post Value</th>
                                <th className="text-center">Unit of Measurement</th>
                                </tr>
                                </thead>
                                <tbody className="tbody_bor_top">
                                {ad.parameters.map(function (
                                    para,
                                    para_index
                                  ) {
                                      return (
                                        <tr>
                                          <td>{para.parameter_text}</td>
                                          <td className="text-center">
                                          {para.pre_value}
                                          </td>
                                          <td className="text-center">
                                          {para.post_value}
                                          </td>
                                          <td className="text-center">
                                          {para.unit_of_measure}
                                          </td>
                                        </tr>
                                      )})}
                                {/* );
                                })} */}
                                </tbody>
                                </table>
                                </div>
                                </Col>
                                </Row>
                                <br />
                                <Row>
                                <Col lg={12}>
                                <p>
                                <b>INFO DATA</b>
                                </p>
                                <div className="table-responsive">
                                <table className="table table-sm m-0 table-bordered">
                                <thead  className="adas_thead">
                                <tr>
                                <th>Info</th>
                                <th className="text-center">Pre Value</th>
                                <th className="text-center">Post Value</th>
                                </tr>
                                </thead>
                                <tbody className="tbody_bor_top">
                                {ad.info.map(function (
                                    inf,
                                    inf_index
                                  ) {
                                      return (
                                        <tr>
                                          <td>{inf.info_text}</td>
                                          <td className="text-center">
                                          {inf.pre_value}
                                          </td>
                                          <td className="text-center">
                                          {inf.post_value}
                                          </td>
                                        </tr>
                                      )})}
                                
                                {/* );
                                })} */}
                                </tbody>
                                </table>
                                </div>
                                </Col>
                                
                                </Row>
                                <br />
                                <hr/>
                                </Card>
                                  )
                                }
                                )}
                              </div>
                        );
                      })}
                  {this.state.adas_precheck !== undefined ?
                    <div className="">
                    <h5 className="mt-0 mb-3 text-center diagnosis_report_head">ALIGNMENT CHECK</h5>
                  <div className="table-responsive">
                    <table className="table table-sm m-0 table-bordered">
                    
                    <tbody>
                      <tr>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_half_toe_front_left}`}>Half-toe: {this.state.adas_precheck.half_toe_front_left}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_toe_front}`}>Front Toe: {this.state.adas_precheck.toe_front}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_half_toe_front_right}`}>Half-toe: {this.state.adas_precheck.half_toe_front_right}</td>
                      </tr>
                      <tr>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_camber_front_left}`}>Camber: {this.state.adas_precheck.camber_front_left}</td>
                        <td></td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_camber_front_right}`}>Camber: {this.state.adas_precheck.camber_front_right}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td><img
                            src={carTop}
                            alt="car_top"
                            className="car_top"
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_camber_rear_left}`}>Camber: {this.state.adas_precheck.camber_rear_left}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_thrust_angle}`}>Thrust angle: {this.state.adas_precheck.thrust_angle}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_camber_rear_right}`}>Camber: {this.state.adas_precheck.camber_rear_right}</td>
                      </tr>
                      <tr>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_half_toe_rear_left}`}>Half-toe: {this.state.adas_precheck.half_toe_rear_left}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_toe_rear}`}>Rear Toe: {this.state.adas_precheck.toe_rear}</td>
                        <td className={`"text-center adas_precheck_${this.state.adas_precheck.range_half_toe_rear_right}`}>Half-toe: {this.state.adas_precheck.half_toe_rear_right}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr/>
                  <h5 className="text-center">RESULT: {this.state.adas_precheck.result == "OK" ? <span class="badge badge-success">OK</span> : <span class="badge badge-danger">NOT OK</span>}</h5>
                    </div>
                    </div>
                    : "" 
                    }
                    </div>
                  : "" 
                  }
                          <br />
                          <br />
                          <div className="d-print-none">
                            <div className="float-right">
                              <Link
                                to="#"
                                onClick={() => {
                                  window.print();
                                }}
                                className="btn btn-mm waves-effect waves-light"
                              >
                                <i className="fa fa-print"> Stampa Report</i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            {/* <div className="col-1"></div> */}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisReport);
