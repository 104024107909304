import React, { Component } from "react";
import { MetaTags } from "react-meta-tags";
import { VectorMap } from "react-jvectormap";
import "../../css/jquery-jvectormap.css";
import "../../assets/scss/custom.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from "moment";
import "chartist/dist/scss/chartist.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import filterFactory, {
  textFilter,
  selectFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Sugar } from "react-preloaders";
import $ from "jquery";
import config from "./../../helpers/Constants";
import ReactExport from "react-data-export-fix1";
import PulseLoader from "react-spinners/PulseLoader";

const strumselectOptions = {
  "Div.0": "DIV.0",
  "iONE Cruiser": "iONE Cruiser",
};

let serialFilter;
let brandFilter;
let templateFilter;
let guyFilter;
let vinFilter;
let plateNoFilter;
let kwFilter;
let engineCodeFilter;
let versionFilter;
let vehicleDBFilter;

const DiagnosisTypeOptions = {
  Customers: "Customers",
  Internal: "Internal",
};
const socselectOptions = {
  MM: "Magneti Marelli",
  TM: "Tecnomotor",
};

const fuelOptions = {
  D: "Diesel",
  B: "Petrol",
};

const busOptions = {
  green_bus: "GREEN BUS",
  red_bus: "RED BUS",
  no_bus: "NO BUS",
};

const lingselectOptions = {
  "it-IT": "Italian",
  "en-US": "English US",
  "en-ES": "English ES",
  "cs-CZ": "Czech",
  "es-ES": "Spanish",
  "fr-FR": "French",
  "bg-BG": "Bulgarian",
  "hu-HU": "Hungarian",
  "nl-NL": "Dutch",
  "ro-RO": "Romanian",
  "de-DE": "German",
  "pt-PT": "Portuguese",
  "pl-PL": "Polish",
};

const { ExportCSVButton } = CSVExport;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      diagnosis_export_all: [],
      visible: false,
      modal_standard: false,
      default_date: new Date(),
      default: false,
      start_date: new Date(),
      end_date: new Date(),
      date: new Date(),
      tablewidth: 8,
      diagnosis_total: 0,
      export_btn: false,
      logged_user: "",
      loading: true,
      tabledataloading: false,
      export_progress_text: false,
      export_progress: false,
      is_filter_data: false,
      filter_start_date: "",
      filter_end_date: "",
    };

    this.tog_standard = this.tog_standard.bind(this);
    i18next.changeLanguage(localStorage.getItem("lang"));
    // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.getFilteredDateData = this.getFilteredDateData.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
    this.onExportButtonClick = this.onExportButtonClick.bind(this);
    this.tog_export = this.tog_export.bind(this);
  }

  //DatePicker
  handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }

  handleEnd(date) {
    this.setState({ end_date: date });
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_map() {
    document.getElementsByClassName("map_div")[0].style.display = "none";
    document.getElementsByClassName("hide_map")[0].style.display = "none";
    document.getElementsByClassName("show_map")[0].style.display = "block";
    localStorage.setItem("map_display", "no");
    this.setState({ tablewidth: 12 });
  }

  show_map() {
    document.getElementsByClassName("map_div")[0].style.display = "block";
    document.getElementsByClassName("hide_map")[0].style.display = "block";
    document.getElementsByClassName("show_map")[0].style.display = " none";
    localStorage.setItem("map_display", "yes");
    this.setState({ tablewidth: 8 });
  }

  tog_export() {
    this.setState((prevState) => ({
      modal_export: !prevState.modal_export,
    }));
    this.removeBodyCss();
  }

  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  handleClick = (e, countryCode) => {
    setTimeout(() => {
      Array.from(document.getElementsByClassName("jvectormap-tip")).forEach(
        (el) => {
          el.style.display = "none";
        }
      );
    }, 100);
    this.props.history.push("/diagnosis_continent_all?code=" + countryCode);
  };

  componentDidMount() {
    this.setState({
      is_filter_data: false,
    });

    localStorage.removeItem("DiagnosisSelectedArray");
    var DiagnosisSelectedArray = [];
    localStorage.setItem(
      "DiagnosisSelectedArray",
      JSON.stringify(DiagnosisSelectedArray)
    );

    localStorage.removeItem("DiagnosisFiltersArray");
    var DiagnosisFiltersArray = [];
    localStorage.setItem(
      "DiagnosisFiltersArray",
      JSON.stringify(DiagnosisFiltersArray)
    );
    $(".vertical-center i").on("click", function () {
      var pwidth = 400;

      if ($(this).hasClass("left")) {
        $(".react-bootstrap-table").scrollLeft(
          $(".react-bootstrap-table").scrollLeft() - pwidth
        );
      } else {
        $(".react-bootstrap-table").scrollLeft(
          $(".react-bootstrap-table").scrollLeft() + pwidth
        );
      }
    });

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    var dataSource = localStorage.getItem("data_source");
    var dataSourceFinal;
    if (dataSource === "all") {
      dataSourceFinal = "list_all_diagnosis";
    } else if (dataSource === "production") {
      dataSourceFinal = "diagnosis";
    } else {
      dataSourceFinal = "diagnosis_test";
    }

    //console.log(authUser);
    this.setState({
      logged_user: user_name,
    });

    if (
      authUser.role === "Administrator" ||
      authUser.role === "Diagnosis Manager" ||
      authUser.role === "Administrator and Site Org" ||
      authUser.role === "Commercial"
    ) {
      this.setState({
        export_btn: true,
        map_btn: true,
      });
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        if (findresponses.diagnosis === 0) {
          window.history.back();
        }
      });
    const map_show = localStorage.getItem("map_display");
    if (map_show === "yes") {
      document.getElementsByClassName("show_map")[0].style.display = "none";
      document.getElementsByClassName("map_div")[0].style.display = "block";
      document.getElementsByClassName("hide_map")[0].style.display = "block";
      this.setState({
        tablewidth: 8,
      });
    } else {
      document.getElementsByClassName("show_map")[0].style.display = "block";
      document.getElementsByClassName("map_div")[0].style.display = "none";
      document.getElementsByClassName("hide_map")[0].style.display = "none";
      this.setState({
        tablewidth: 12,
      });
    }

    const data_visible_days = localStorage.getItem("data_visible_days");
    const selected_product = localStorage.getItem("sel_product");
    const user_language = localStorage.getItem("user_db_lang");

    if (!data_visible_days) {
      var days = "14";
    } else {
      days = data_visible_days;
    }
    if (!selected_product) {
      var select_product = "all";
    } else {
      select_product = selected_product;
    }

    var arr_data_type = localStorage.getItem("data_source");

    fetch(
      config.API +
        "diagnosis?days=" +
        days +
        "&sel_product=" +
        select_product +
        "&company=Nexion&page=1" +
        "&user_lang=" +
        user_language,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
          arr_data_type: arr_data_type,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          products: findresponse.diagnosis,
          diagnosis_total: findresponse.diagnosis_total,
          loading: false,
        });
      });
  }

  getFilteredDateData() {
    //console.log("Triggered");
    var dataSource = localStorage.getItem("data_source");
    var dataSourceFinal;
    if (dataSource === "all") {
      dataSourceFinal = "list_all_diagnosis";
    } else if (dataSource === "production") {
      dataSourceFinal = "diagnosis";
    } else {
      dataSourceFinal = "diagnosis_test";
    }

    const selected_product = localStorage.getItem("sel_product");
    const user_language = localStorage.getItem("user_db_lang");

    const starting_date = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const ending_date = moment(this.state.end_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (!selected_product) {
      var select_product = "all";
    } else {
      select_product = selected_product;
    }
    this.setState({
      is_filter_data: true,
      filter_start_date: starting_date,
      filter_end_date: ending_date,
    });
    var arr_data_type = localStorage.getItem("data_source");

    fetch(
      config.API +
        "diagnosis?" +
        "starting_date=" +
        starting_date +
        "&ending_date=" +
        ending_date +
        "&sel_product=" +
        select_product +
        "&company=Nexion&page=1" +
        "&user_lang=" +
        user_language,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
          arr_data_type: arr_data_type,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          products: findresponse.diagnosis,
          diagnosis_total: findresponse.diagnosis_total,
        });
      });
    this.tog_standard();
  }

  handleExportSubmit(event, values) {
    const user_language = localStorage.getItem("user_db_lang");

    var exp_values = {
      type: "Export",
      note: "Diagnosis Export",
      company: "Nexion",
      language: user_language,
    };
    const username = this.state.logged_user;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: username,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "export_import_logs", exp_values, options).then(
        (user) => {},
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  }

  resetpage() {
    window.location.reload(false);
  }

  handleOnSelect = (row, isSelect) => {
    var selData = JSON.parse(
      localStorage.getItem("DiagnosisSelectedArray") || "[]"
    );
    function remove(key) {
      var i = selData.findIndex((seldat) => seldat.key === key);
      if (i !== -1) {
        selData.splice(i, 1);
        localStorage.setItem("DiagnosisSelectedArray", JSON.stringify(selData));
      }
    }
    if (isSelect === true) {
      var nkey = row.key;
      var newSelectedArray = JSON.parse(
        localStorage.getItem("DiagnosisSelectedArray")
      );
      newSelectedArray.push(row);
      localStorage.setItem(
        "DiagnosisSelectedArray",
        JSON.stringify(newSelectedArray)
      );
    } else {
      remove(row.key);
    }
  };

  onExportButtonClick() {
    const filters = JSON.parse(localStorage.getItem("DiagnosisFiltersArray"));
    const user_language = localStorage.getItem("user_db_lang");

    //console.log("exported");
    this.tog_export();
    this.setState({
      export_progress_text: true,
      export_progress: false,
    });
    const data_visible_days = localStorage.getItem("data_visible_days");
    const selected_product = localStorage.getItem("sel_product");
    localStorage.setItem("DiagnosisFiltersArray", JSON.stringify(filters));

    this.setState({
      is_filter_data: true,
      filter_start_date: starting_date,
      filter_end_date: ending_date,
    });

    const starting_date = moment(this.state.start_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const ending_date = moment(this.state.end_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    let filter_start_date = "";
    let filter_end_date = "";
    let is_filter_data = "no";

    if (this.state.is_filter_data == true) {
      filter_start_date = this.state.filter_start_date;
      filter_end_date = this.state.filter_end_date;
      is_filter_data = "yes";
    }

    if (!data_visible_days) {
      var days = "14";
    } else {
      days = data_visible_days;
    }
    if (!selected_product) {
      var select_product = "all";
    } else {
      select_product = selected_product;
    }

    if (filters["id"] && filters["id"] !== undefined) {
      var id = filters["id"].filterVal;
    } else {
      var id = [];
    }

    if (filters["serial_number"] && filters["serial_number"] !== undefined) {
      var serial_number = filters["serial_number"].filterVal;
    } else {
      var serial_number = [];
    }

    if (filters["device_name"] && filters["device_name"] !== undefined) {
      var device_name = filters["device_name"].filterVal;
    } else {
      var device_name = [];
    }

    if (filters["brand"] && filters["brand"] !== undefined) {
      var brand = filters["brand"].filterVal;
    } else {
      var brand = [];
    }

    if (filters["family"] && filters["family"] !== undefined) {
      var family = filters["family"].filterVal;
    } else {
      var family = [];
    }

    if (filters["chassis"] && filters["chassis"] !== undefined) {
      var chassis = filters["chassis"].filterVal;
    } else {
      var chassis = [];
    }

    if (filters["fuel"] && filters["fuel"] !== undefined) {
      var fuel = filters["fuel"].filterVal;
    } else {
      var fuel = [];
    }

    if (filters["vin"] && filters["vin"] !== undefined) {
      var vin = filters["vin"].filterVal;
    } else {
      var vin = [];
    }

    if (filters["plate_number"] && filters["plate_number"] !== undefined) {
      var plate_number = filters["plate_number"].filterVal;
    } else {
      var plate_number = [];
    }

    if (filters["kw"] && filters["kw"] !== undefined) {
      var kw = filters["kw"].filterVal;
    } else {
      var kw = [];
    }

    if (
      filters["engine_code_clear"] &&
      filters["engine_code_clear"] !== undefined
    ) {
      var engine_code_clear = filters["engine_code_clear"].filterVal;
    } else {
      var engine_code_clear = [];
    }

    if (filters["software"] && filters["software"] !== undefined) {
      var software = filters["software"].filterVal;
    } else {
      var software = [];
    }

    if (
      filters["vehicle_database"] &&
      filters["vehicle_database"] !== undefined
    ) {
      var vehicle_database = filters["vehicle_database"].filterVal;
    } else {
      var vehicle_database = [];
    }

    if (filters["company"] && filters["company"] !== undefined) {
      var company = filters["company"].filterVal;
    } else {
      var company = [];
    }

    if (filters["language"] && filters["language"] !== undefined) {
      var language = filters["language"].filterVal;
    } else {
      var language = [];
    }

    if (filters["diagnosis_date"] && filters["diagnosis_date"] !== undefined) {
      var date_selected = filters["diagnosis_date"].filterVal.date;
      var comparator = filters["diagnosis_date"].filterVal.comparator;
      if (date_selected !== null && comparator !== null) {
        var f_date_selected = moment(date_selected).format("YYYY-MM-DD");
        var f_comparator = comparator;
      } else {
        var f_date_selected = [];
        var f_comparator = [];
      }
    } else {
      var f_date_selected = [];
      var f_comparator = [];
    }

    if (filters["ok_count"] && filters["ok_count"] !== undefined) {
      var bus_filter = filters["ok_count"].filterVal;
    } else {
      var bus_filter = [];
    }
    var arr_data_type = localStorage.getItem("data_source");

    fetch(
      config.API +
        "list_all_diagnosis_export_all?days=" +
        days +
        "&sel_product=" +
        select_product +
        "&company=Nexion&page=1" +
        "&user_lang=" +
        user_language,
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
          arr_data_type: arr_data_type,
          arr_id: id,
          arr_serial_number: serial_number,
          arr_device_name: device_name,
          arr_brand: brand,
          arr_family: family,
          arr_chassis: chassis,
          arr_fuel: fuel,
          arr_vin: vin,
          arr_plate_number: plate_number,
          arr_kw: kw,
          arr_engine_code_clear: engine_code_clear,
          arr_software: software,
          arr_vehicle_database: vehicle_database,
          arr_company: company,
          arr_language: language,
          arr_date_selected: f_date_selected,
          arr_comparator: f_comparator,
          arr_bus_filter: bus_filter,
          is_filter_data: is_filter_data,
          filter_start_date: filter_start_date,
          filter_end_date: filter_end_date,
          arr_date_selected: f_date_selected,
          arr_comparator: f_comparator,
        },
      }
    )
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        this.setState({
          diagnosis_export_all: findresponses.diagnosis,
          export_progress_text: false,
          export_progress: true,
        });
      });
  }

  render() {
    const { t } = this.props;
    // let brandFilter;
    // let serialFilter;
    // let brandFilter;
    // let templateFilter;
    // let guyFilter;
    // let vinFilter;
    // let plateNoFilter;
    // let kwFilter;
    // let engineCodeFilter;
    // let versionFilter;
    // let versionDBFilter;
    const columns = [
      {
        text: "Diagnosis Type",
        dataField: "test_type",
        formatter: (rowContent, row) => {
          const test_type = row.test_type;
          if (test_type === "Customers") {
            var data_type = "Production";
          } else {
            data_type = "Internal";
          }
          return data_type;
        },
      },
      {
        text: t("diagnosispage.diagnosis_id"),
        dataField: "diagnosis_id", 
        filter: textFilter(),
        formatter: (rowContent, row) => {
          const id = row.diagnosis_id;
          const test_type = row.test_type;
          return (
            <Link to={"/diagnosis_details?diagnosis_id=" + id+"&test_type=" +test_type} target="_blank">
              <button className="btn btn-outline-nexion btn lg">
                {row.diagnosis_id}
              </button>
            </Link>
          );
        },
      },
      {
        text: t("diagnosispage.ser_no"),
        dataField: "serial_number",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleSerialClick(row.serial_number);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            serialFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.strum"),
        dataField: "device_name",
        filter: selectFilter({
          options: strumselectOptions,
        }),
      },
      {
        text: t("diagnosispage.brand"),
        dataField: "brand",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleBrandClick(row.brand);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            brandFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.template"),
        dataField: "family",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleTemplateClick(row.family);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            templateFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.guy"),
        dataField: "chassis",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleGuyClick(row.chassis);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            guyFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.fuel"),
        dataField: "fuel",
        formatter: (rowContent, row) => {
          const fuel = row.fuel;
          if (fuel === "D") {
            var fuels = "Diesel";
          } else {
            fuels = "Petrol";
          }
          return fuels;
        },
        filter: selectFilter({
          options: fuelOptions,
        }),
      },
      {
        text: t("diagnosispage.year"),
        dataField: "variant_year_from",
        // filter: textFilter(),
        formatter: (rowContent, row) => {
          const s_year = row.variant_year_from;
          const e_year = row.variant_year_to;
          return s_year + "-" + e_year;
        },
      },
      // {
      //   text: "BUS ECU Status",
      //   formatter: (rowContent, row) => {
      //     var bus_count = row.bus_count.bus_count;
      //     if (bus_count === 0) {
      //       return <i className="fas fa-info-circle no_bus"></i>;
      //     } else if (bus_count > 0) {
      //       if (row.ok_count.ok_count > 0) {
      //         return <i className="fas fa-check-circle one_ecu_green"></i>;
      //       } else {
      //         return <i className="fas fa-times-circle all_ecu_red"></i>;
      //       }
      //     }
      //   },
      // },
      {
        text: "BUS ECU Status",
        dataField: "ok_count",
        filter: selectFilter({
          options: busOptions,
        }),
        formatter: (rowContent, row) => {
          var bus_count = row.bus_count;
          var ok_count = row.ok_count;
          if (bus_count === "NO BUS" && ok_count === "NOT OK") {
            return (
              <div>
                <i className="fas fa-info-circle no_bus"></i>
                {/* {bus_count + " " + ok_count} */}
              </div>
            );
          } else if (bus_count === "BUS" && ok_count === "OK") {
            return (
              <div>
                <i className="fas fa-check-circle one_ecu_green"></i>
                {/* {bus_count + " " + ok_count} */}
              </div>
            );
          } else {
            return (
              <div>
                <i className="fas fa-times-circle all_ecu_red"></i>
                {/* {bus_count + " " + ok_count} */}
              </div>
            );
          }
        },
      },
      {
        text: "VIN",
        dataField: "vin",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleVinClick(row.vin);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            vinFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.id_riga"),
        dataField: "plate_number",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handlePlateNoClick(row.plate_number);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            plateNoFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.kw"),
        dataField: "kw",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleKwClick(row.kw);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            kwFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.engine_code"),
        dataField: "engine_code_clear",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleEngineCodeClick(row.engine_code_clear);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            engineCodeFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.version"),
        dataField: "software",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleVersionClick(row.software);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            versionFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.ver_db"),
        dataField: "vehicle_database",
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            handleVehicleDBClick(row.vehicle_database);
          },
        },
        filter: textFilter({
          getFilter: (filter) => {
            vehicleDBFilter = filter;
          },
        }),
      },
      {
        text: t("diagnosispage.soc"),
        dataField: "company",
        filter: selectFilter({
          options: socselectOptions,
        }),
      },
      {
        text: t("diagnosispage.ling"),
        dataField: "language",
        filter: selectFilter({
          options: lingselectOptions,
        }),
      },
      {
        text: t("diagnosispage.diag_date"),
        dataField: "diagnosis_date",
        filter: dateFilter(),
        formatter: (rowContent, row) => {
          var up_date = row.diagnosis_date
            .replace(/T/, " ")
            .replace(/\..+/, "");
          return up_date;
        },
      },
    ];

    const handleSerialClick = (serial) => {
      serialFilter(serial);
    };
    const handleBrandClick = (brand) => {
      brandFilter(brand);
    };
    const handleTemplateClick = (template) => {
      templateFilter(template);
    };
    const handleGuyClick = (guy) => {
      guyFilter(guy);
    };
    const handleVinClick = (vin) => {
      vinFilter(vin);
    };
    const handlePlateNoClick = (plate_no) => {
      plateNoFilter(plate_no);
    };
    const handleKwClick = (kw) => {
      kwFilter(kw);
    };
    const handleEngineCodeClick = (engine_code) => {
      engineCodeFilter(engine_code);
    };
    const handleVersionClick = (version) => {
      versionFilter(version);
    };
    const handleVehicleDBClick = (ver_db) => {
      vehicleDBFilter(ver_db);
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectAll: true,
      onSelect: this.handleOnSelect,
    };

    const onTableChange = (type, newState) => {
      //console.log("tri");
      const filters = newState.filters;

      const data_visible_days = localStorage.getItem("data_visible_days");
      const selected_product = localStorage.getItem("sel_product");
      const user_language = localStorage.getItem("user_db_lang");

      localStorage.setItem("DiagnosisFiltersArray", JSON.stringify(filters));
      
      let filter_start_date = "";
      let filter_end_date = "";
      let is_filter_data = "no";

      if (this.state.is_filter_data == true) {
        filter_start_date = this.state.filter_start_date;
        filter_end_date = this.state.filter_end_date;
        is_filter_data = "yes";
      }

      if (!data_visible_days) {
        var days = "14";
      } else {
        days = data_visible_days;
      }
      if (!selected_product) {
        var select_product = "all";
      } else {
        select_product = selected_product;
      }

      if (filters["diagnosis_id"] && filters["diagnosis_id"] !== undefined) {
        var id = filters["diagnosis_id"].filterVal;
      } else {
        var id = [];
      }

      if (filters["serial_number"] && filters["serial_number"] !== undefined) {
        var serial_number = filters["serial_number"].filterVal;
      } else {
        var serial_number = [];
      }

      if (filters["device_name"] && filters["device_name"] !== undefined) {
        var device_name = filters["device_name"].filterVal;
      } else {
        var device_name = [];
      }

      if (filters["test_type"] && filters["test_type"] !== undefined) {
        var test_type = filters["test_type"].filterVal;
      } else {
        var test_type = [];
      }

      if (filters["brand"] && filters["brand"] !== undefined) {
        var brand = filters["brand"].filterVal;
      } else {
        var brand = [];
      }

      if (filters["family"] && filters["family"] !== undefined) {
        var family = filters["family"].filterVal;
      } else {
        var family = [];
      }

      if (filters["chassis"] && filters["chassis"] !== undefined) {
        var chassis = filters["chassis"].filterVal;
      } else {
        var chassis = [];
      }

      if (filters["fuel"] && filters["fuel"] !== undefined) {
        var fuel = filters["fuel"].filterVal;
      } else {
        var fuel = [];
      }

      if (filters["vin"] && filters["vin"] !== undefined) {
        var vin = filters["vin"].filterVal;
      } else {
        var vin = [];
      }

      if (filters["plate_number"] && filters["plate_number"] !== undefined) {
        var plate_number = filters["plate_number"].filterVal;
      } else {
        var plate_number = [];
      }

      if (filters["kw"] && filters["kw"] !== undefined) {
        var kw = filters["kw"].filterVal;
      } else {
        var kw = [];
      }

      if (
        filters["engine_code_clear"] &&
        filters["engine_code_clear"] !== undefined
      ) {
        var engine_code_clear = filters["engine_code_clear"].filterVal;
      } else {
        var engine_code_clear = [];
      }

      if (filters["software"] && filters["software"] !== undefined) {
        var software = filters["software"].filterVal;
      } else {
        var software = [];
      }

      if (
        filters["vehicle_database"] &&
        filters["vehicle_database"] !== undefined
      ) {
        var vehicle_database = filters["vehicle_database"].filterVal;
      } else {
        var vehicle_database = [];
      }

      if (filters["company"] && filters["company"] !== undefined) {
        var company = filters["company"].filterVal;
      } else {
        var company = [];
      }

      if (filters["language"] && filters["language"] !== undefined) {
        var language = filters["language"].filterVal;
      } else {
        var language = [];
      }

      // if (filters["bus_count"] && filters["bus_count"] !== undefined) {
      //   var bus_filter = filters["bus_count"].filterVal;
      // } else {
      //   var bus_filter = [];
      // }

      //console.log(bus_filter);

      if (filters["ok_count"] && filters["ok_count"] !== undefined) {
        var ok_filter = filters["ok_count"].filterVal;
      } else {
        var ok_filter = [];
      }

      if (
        filters["diagnosis_date"] &&
        filters["diagnosis_date"] !== undefined
      ) {
        var date_selected = filters["diagnosis_date"].filterVal.date;
        var comparator = filters["diagnosis_date"].filterVal.comparator;
        if (date_selected !== null && comparator !== null) {
          var f_date_selected = moment(date_selected).format("YYYY-MM-DD");
          var f_comparator = comparator;
        } else {
          var f_date_selected = [];
          var f_comparator = [];
        }
      } else {
        var f_date_selected = [];
        var f_comparator = [];
      }

      var arr_data_type = localStorage.getItem("data_source");

      this.setState({ tabledataloading: true });
      fetch(
        config.API +
          "diagnosis?days=" +
          days +
          "&sel_product=" +
          select_product +
          "&company=Nexion&page=" +
          newState.page +
          "&user_lang=" +
          user_language,
        {
          headers: {
            Nexion_API_Key: config.API_KEY,
            arr_data_type: arr_data_type,
            arr_id: id,
            arr_serial_number: serial_number,
            arr_device_name: device_name,
            arr_test_type: test_type,
            arr_brand: brand,
            arr_family: family,
            arr_chassis: chassis,
            arr_fuel: fuel,
            arr_vin: vin,
            arr_plate_number: plate_number,
            arr_kw: kw,
            arr_engine_code_clear: engine_code_clear,
            arr_software: software,
            arr_vehicle_database: vehicle_database,
            arr_company: company,
            arr_language: language,
            //arr_bus_filter: bus_filter,
            arr_ok_filter: ok_filter,
            is_filter_data: is_filter_data,
            filter_start_date: filter_start_date,
            filter_end_date: filter_end_date,
            arr_date_selected: f_date_selected,
            arr_comparator: f_comparator,
          },
        }
      )
        .then((Response) => Response.json())
        .then((findresponse) => {
         // console.log(findresponse);
          this.setState({
            products: [],
            diagnosis_total: 0,
          });
          this.setState({
            products: findresponse.diagnosis,
            diagnosis_total: findresponse.diagnosis_total,
            tabledataloading: false,
            loading: false,
          });
        });
    };

    const options = {
      sizePerPage: 20,
      pageStartIndex: 1,
      withFirstAndLast: true,
      firstPageText: t("versionpage.firstPageText"),
      prePageText: t("versionpage.prePageText"),
      nextPageText: t("versionpage.nextPageText"),
      lastPageText: t("versionpage.lastPageText"),
      nextPageTitle: t("versionpage.nextPageTitle"),
      prePageTitle: t("versionpage.prePageTitle"),
      firstPageTitle: t("versionpage.firstPageTitle"),
      lastPageTitle: t("versionpage.lastPageTitle"),
      showTotal: true,
      showTotalTitle: "Show Total",
      totalSize: this.state.diagnosis_total,
      sizePerPageList: [
        {
          text: "20",
          value: 20,
        },
      ],
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div className="container-fluid">
        <ToolkitProvider
          keyField="id"
          data={this.state.products}
          columns={columns}
          exportCSV={{
            exportAll: false,
            onlyExportSelection: true,
            fileName: "Diagnosi Data " + this.state.date + ".csv",
          }}
        >
          {(props) => (
            <div>
              <ul className="export_btns">
                <li>
                  <button
                    type="button"
                    onClick={this.tog_standard}
                    className="btn filter_btn waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i className="ti-filter"></i>
                  </button>
                </li>
                {this.state.export_btn === true ? (
                  <li>
                    <Dropdown
                      isOpen={this.state.btnprimary1}
                      direction="left"
                      toggle={() =>
                        this.setState({
                          btnprimary1: !this.state.btnprimary1,
                        })
                      }
                    >
                      <DropdownToggle tag="button" className="btn btn-success">
                        {t("diagnosispage.exp")}
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem onClick={this.handleExportSubmit}>
                          <ExportCSVButton {...props.csvProps}>
                            {t("diagnosispage.exp_sel")}
                          </ExportCSVButton>
                        </DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}>
                          <CSVLink
                            className="react-bs-table-csv-btn btn btn-default"
                            filename={
                              "Diagnosi Data " + this.state.date + ".csv"
                            }
                            data={this.state.products}
                          >
                            {t("diagnosispage.exp_all")}
                          </CSVLink>
                        </DropdownItem> */}

                        <DropdownItem onClick={this.handleExportSubmit}>
                          <ExcelFile
                            element={
                              <span className="react-bs-table-csv-btn btn btn-default">
                                {t("userspage.exp_sel")}
                              </span>
                            }
                            filename="Diagnosis Data"
                          >
                            <ExcelSheet
                              data={JSON.parse(
                                localStorage.getItem("DiagnosisSelectedArray")
                              )}
                              name="Diagnosis"
                            >
                            <ExcelColumn label="Test Type" value="test_type" />
                              <ExcelColumn label="ID" value="diagnosis_id" />
                              <ExcelColumn
                                label="Serial Number"
                                value="serial_number"
                              />
                              <ExcelColumn
                                label="Device Name"
                                value="device_name"
                              />
                              <ExcelColumn label="Language" value="language" />
                              <ExcelColumn label="Company" value="company" />
                              <ExcelColumn label="Market" value="market" />
                              <ExcelColumn label="Password" value="password" />
                              <ExcelColumn
                                label="Expiring Date"
                                value="expiring_date"
                              />
                              <ExcelColumn
                                label="Diagnosis Date"
                                value="diagnosis_date"
                              />
                              <ExcelColumn
                                label="Date Added"
                                value="date_added"
                              />
                              <ExcelColumn label="Software" value="software" />
                              <ExcelColumn
                                label="Vehicle Database"
                                value="vehicle_database"
                              />
                              <ExcelColumn label="Brand" value="brand" />
                              <ExcelColumn label="Family" value="family" />
                              <ExcelColumn label="Chassis" value="chassis" />
                              <ExcelColumn
                                label="Variant Year From"
                                value="variant_year_from"
                              />
                              <ExcelColumn
                                label="Variant Year To"
                                value="variant_year_to"
                              />
                              <ExcelColumn label="Fuel" value="fuel" />
                              <ExcelColumn
                                label="Engine Code Clear"
                                value="engine_code_clear"
                              />
                              <ExcelColumn label="kW" value="kw" />
                              <ExcelColumn
                                label="Plate Number"
                                value="plate_number"
                              />
                              <ExcelColumn label="Vin" value="vin" />
                              <ExcelColumn
                                label="Continent Code"
                                value="continent_code"
                              />
                              <ExcelColumn
                                label="Bus Count"
                                value="bus_count"
                              />
                              <ExcelColumn label="OK Count" value="ok_count" />
                            </ExcelSheet>
                          </ExcelFile>
                        </DropdownItem>
                        <DropdownItem
                          // onClick={() => {
                          //   this.onExportButtonClick();
                          //   this.handleExportSubmit();
                          // }}
                        >
                          <span className="react-bs-table-csv-btn btn btn-default">
                            {t("userspage.exp_all")}
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <button
                    type="button"
                    onClick={() => this.tog_map()}
                    className="btn hide_map map_hide btn-danger waves-effect waves-light"
                  >
                    {t("diagnosispage.hide_map")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => this.show_map()}
                    className="btn show_map map_hide btn-danger waves-effect waves-light"
                  >
                    {t("diagnosispage.show_map")}
                  </button>
                </li>
              </ul>
              <br />
              <br />
              <hr />
              <div className="table-wrapper">
                <div className="vertical-center diag-vertical-center">
                  <i class="ion ion-ios-arrow-dropleft-circle left"></i>
                  <i class="ion ion-ios-arrow-dropright-circle right"></i>
                </div>
                <BootstrapTable
                  striped
                  hover
                  onTableChange={onTableChange}
                  {...props.baseProps}
                  selectRow={selectRow}
                  filter={filterFactory()}
                  {...paginationTableProps}
                  pagination={paginationFactory(options)}
                  wrapperClasses="table-responsive diagnsosis_table jira_table"
                  remote={{
                    filter: true,
                    pagination: true,
                  }}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
    return (
      <React.Fragment>
        <MetaTags>
          <title>Diagnosis | Nexion Engineering Web Portal</title>
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#016362"
          color={"#ffffff"}
        />
        <div className="tabledataloader">
          <PulseLoader
            color={"#016362"}
            loading={this.state.tabledataloading}
            size={25}
          />
        </div>
        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("diagnosispage.get_ver_data")}
            </h5>
            <button
              type="button"
              onClick={() => this.setState({ modal_standard: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form action="#">
              <FormGroup className="mb-0 forminput">
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.start_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.start_date}
                      selectsStart
                      id="date_pick"
                      className="date_pick form-control"
                      placeholderText="From"
                      name="start_dates"
                      value={this.state.start_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleStart}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <label>{t("diagnosispage.end_date")}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker
                      selected={this.state.end_date}
                      selectsEnd
                      id="date_pick"
                      className="form-control"
                      name="end_dates"
                      value={this.state.end_date}
                      startDate={this.state.start_date}
                      endDate={this.state.end_date}
                      onChange={this.handleEnd}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.resetpage}
              className="btn btn-warning waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.reset_filter")}
            </button>
            <button
              type="button"
              onClick={this.tog_standard}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              {t("diagnosispage.cancel")}
            </button>
            <button
              type="button"
              onClick={this.getFilteredDateData}
              className="btn filter_btn waves-effect waves-light"
            >
              {t("diagnosispage.proceed")}
            </button>
          </div>
        </Modal>

        <Modal isOpen={this.state.modal_export} toggle={this.tog_export}>
          <div className="modal-body">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    {this.state.export_progress_text === true ? (
                      <p>Please wait Excel file is being generated</p>
                    ) : (
                      ""
                    )}
                    {this.state.export_progress === true ? (
                      <ExcelFile
                        element={
                          <button className="react-bs-table-csv-btn btn btn-default">
                            Download
                          </button>
                        }
                        filename="Diagnosis Data"
                      >
                        <ExcelSheet
                          data={this.state.diagnosis_export_all}
                          name="Diagnosis"
                        ><ExcelColumn label="Test Type" value="test_type" />
                          <ExcelColumn label="ID" value="diagnosis_id" />
                          <ExcelColumn
                            label="Serial Number"
                            value="serial_number"
                          />
                          <ExcelColumn
                            label="Device Name"
                            value="device_name"
                          />
                          <ExcelColumn label="Language" value="language" />
                          <ExcelColumn label="Company" value="company" />
                          <ExcelColumn label="Market" value="market" />
                          <ExcelColumn label="Password" value="password" />
                          <ExcelColumn
                            label="Expiring Date"
                            value="expiring_date"
                          />
                          <ExcelColumn
                            label="Diagnosis Date"
                            value="diagnosis_date"
                          />
                          <ExcelColumn label="Date Added" value="date_added" />
                          <ExcelColumn label="Software" value="software" />
                          <ExcelColumn
                            label="Vehicle Database"
                            value="vehicle_database"
                          />
                          <ExcelColumn label="Brand" value="brand" />
                          <ExcelColumn label="Family" value="family" />
                          <ExcelColumn label="Chassis" value="chassis" />
                          <ExcelColumn
                            label="Variant Year From"
                            value="variant_year_from"
                          />
                          <ExcelColumn
                            label="Variant Year To"
                            value="variant_year_to"
                          />
                          <ExcelColumn label="Fuel" value="fuel" />
                          <ExcelColumn
                            label="Engine Code Clear"
                            value="engine_code_clear"
                          />
                          <ExcelColumn label="kW" value="kw" />
                          <ExcelColumn
                            label="Plate Number"
                            value="plate_number"
                          />
                          <ExcelColumn label="Vin" value="vin" />
                          <ExcelColumn
                            label="Continent Code"
                            value="continent_code"
                          />
                          <ExcelColumn label="Bus Count" value="bus_count" />
                          <ExcelColumn label="OK Count" value="ok_count" />
                        </ExcelSheet>
                      </ExcelFile>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.tog_export}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
        <div className="container-fluid homepageFirstRow">
          <Row>
            <Col xl={0} className="map_div">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    <i className="ion ion-md-analytics text-nexion"></i>{" "}
                    {t("homepage.countries")}
                  </h4>
                  {/* <p>{t('homepage.last_14_days')}</p> */}
                  <Row>
                    <Col xl={12}>
                      <div style={{ width: this.props.width, height: 580 }}>
                        <VectorMap
                          map={"continents_mill"}
                          onRegionClick={this.handleClick} // gets the country code
                          backgroundColor="transparent"
                          ref="map"
                          containerStyle={{
                            width: "100%",
                            height: "80%",
                          }}
                          regionStyle={{
                            initial: {
                              fill: "#e0e0e0",
                              stroke: "none",
                              "stroke-width": 0,
                              "stroke-opacity": 0,
                            },
                            hover: {
                              "fill-opacity": 0.8,
                              cursor: "pointer",
                            },
                            selected: {
                              fill: "#002C77", //what colour clicked country will be
                            },
                            selectedHover: {},
                          }}
                          series={{
                            regions: [
                              {
                                values: {
                                  EU: "#016362",
                                },
                              },
                            ],
                          }}
                          containerClassName="map"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl="10" className="diag_table_col">
              <Card>
                <CardBody>
                  <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
            <Col xl="2" className="diag_menu_col">
              <div id="NewmySidenav">
                <Link to="/diagnosis" className=" waves-effect">
                  <i className="ion ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.diagnosis")}
                </Link>
                <Link to="/undecoded_dtc" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.undecodedDTC")}
                </Link>
                <Link to="/diagnosis_activations" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.activations")}
                </Link>
                <Link to="/diagnosis_communication" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  {t("diagnosispage.diagnosiscommunication")}
                </Link>
                <Link to="/diagnosis_security_gateway" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  Security Gateway Unlock
                </Link>
                <Link to="/adas_diagnosis" className=" waves-effect">
                  <i className="ion  ion-ios-log-in"></i>{" "}
                  ADAS Operations
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div id="mySidenav" className="sidenav">
          <Row>
            <Col sm="3" className="icon_border">
              <i className="ion ion-ios-list" id="right_sidebar_icon"></i>
            </Col>
            <Col sm="9">
              <Link to="/diagnosis_all" className=" waves-effect">
                Diagnosis
              </Link>
              <Link to="/undecoded_dtc_all" className=" waves-effect">
                Undecoded DTC
              </Link>
              <Link to="/diagnosis_activations_all" className=" waves-effect">
                Activations
              </Link>
              <Link to="/diagnosis_communication_all" className=" waves-effect">
                Diagnosis Communication
              </Link>
            </Col>
          </Row>
        </div> */}
      </React.Fragment>
    );
  }
}

export default withTranslation()(Diagnosis);
